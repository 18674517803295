var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('strong',[_vm._v("Conta de administrador ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.loginBikeRegistrada)}}},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"login","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.emailLogin),expression:"emailLogin"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"Digite seu login"},domProps:{"value":(_vm.emailLogin)},on:{"input":function($event){if($event.target.composing){ return; }_vm.emailLogin=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"senha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.senhaLogin),expression:"senhaLogin"}],staticClass:"form-control",class:classes,attrs:{"data-test-id":"input-login-senha","type":"password","placeholder":"Digite sua senha"},domProps:{"value":(_vm.senhaLogin)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loginBikeRegistrada()},"input":function($event){if($event.target.composing){ return; }_vm.senhaLogin=$event.target.value}}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button',{staticClass:"btn btn-success btn-block",attrs:{"data-test-id":"btn-login","type":"submit","disabled":_vm.loadingLogin}},[(!_vm.loadingLogin)?_c('span',[_vm._v("Fazer login e continuar")]):_vm._e(),(_vm.loadingLogin)?_c('span',[_vm._v("Por favor aguarde...")]):_vm._e()])])]}}])}),_c('hr')],1)}
var staticRenderFns = []

export { render, staticRenderFns }