<template>
  <div class="container pt-5">
    <div class="text-center">
      <img
        src="/img/logo-preta.png"
        width="200"
        alt="Logo Bike Registrada"
      />
      
    </div>
    <div class="row pt-5">
      <div class="col-md-4 offset-md-4 col-sm-12">
        <Login></Login>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "../../components/Login";
export default {
  components: {
    Login,
  },
};
</script>

<style scoped></style>
