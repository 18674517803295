<template>
  <div>
    <strong>Conta de administrador </strong>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="mt-2" @submit.prevent="handleSubmit(loginBikeRegistrada)">
        <div class="form-group">
          <ValidationProvider
            name="login"
            rules="required"
            v-slot="{ errors, classes }"
          >
            <input
              v-model="emailLogin"
              type="text"
              class="form-control"
              :class="classes"
              placeholder="Digite seu login"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider
            name="senha"
            rules="required"
            v-slot="{ errors, classes }"
          >
            <input
              v-model="senhaLogin"
              data-test-id="input-login-senha"
              type="password"
              class="form-control"
              :class="classes"
              placeholder="Digite sua senha"
              @keyup.enter="loginBikeRegistrada()"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <button
          data-test-id="btn-login"
          type="submit"
          class="btn btn-success btn-block"
          :disabled="loadingLogin"
        >
          <span v-if="!loadingLogin">Fazer login e continuar</span>
          <span v-if="loadingLogin">Por favor aguarde...</span>
        </button>
      </form>
    </ValidationObserver>

    <hr />
    <!-- <div class="text-center">
      <router-link to="/recuperarSenha">Esqueci minha senha</router-link>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

import config from "../config";
import auth from "../auth";
import captureError from "../helpers/captureError";

export default {
  components: {},
  data() {
    return {
      config,

      emailLogin: undefined,
      senhaLogin: undefined,
      loadingLogin: false,
      loadingLoginFb: false,

      qtdErroLogin: 0,
    };
  },
  methods: {
    async loginBikeRegistrada() {
      this.loadingLogin = true;

      try {
        var response;
        response = await axios.post(`${config.API_URLV2}/auth/LoginAdmin`, {
          login: this.emailLogin,
          senha: this.senhaLogin,
        });

        auth.setUserInfo(response.data);

        this.$router.replace({ path: "segurados" });

        auth.setUserInfo(response.data);

        this.$emit("login-success");
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.invalido
        ) {
          var msg = error.response.data.msg;
          Swal.fire({
            title: msg,
            text: "",
            icon: "error",
            confirmButtonText: "Ok",
          });
          this.loadingLogin = false;
          this.qtdErroLogin++;
          if (this.qtdErroLogin >= 2) {
            this.$router.push("/admin");
          }
        } else {
          captureError(error, this.emailLogin);
          Swal.fire({
            title: "Não foi possível fazer o login",
            text: "Tente novamente mais tarde ou contate o suporte.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
      this.loadingLogin = false;
    },
  },
};
</script>
